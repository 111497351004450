
import { mapState } from 'vuex'

import AccountOfferCard from '@/components/account/default/elements/AccountOfferCard.vue'
import DefaultForm from '@/components/globals/forms/DefaultForm.vue'
import HowToRedeem from '../../elements/HowToRedeem.vue'

export default {
  name: 'AccountRewardRedeem',
  components: {
    HowToRedeem,
    AccountOfferCard,
    DefaultForm,
  },
  props: {
    sections: {
      type: Array,
      default: () => [
        {
          name: 'Offers',
          section: 'offers',
        },
        {
          name: 'Rewards',
          section: 'rewards',
        },
      ]
    },
    section: Object
  },
  computed: {
    ...mapState({
      redeemables: state => state.members.redeemables,
    }),
    hasRedeemables() {
      return this.redeemables && this.redeemables.redeemables?.length > 0
      // return this.section && this.section.redeemables?.length > 0
    },
    redeemFields() {
      return [
        {
          name: 'name',
          label: 'Name',
          placeholder: 'Name',
          // value: this.user?.first_name ? this.user.first_name : null,
          autocomplete: 'name',
          type: 'text',
          required: true,
          valid: /^[A-Za-z\s]*$/,
          errors: {
            invalid: 'Name may only contain letters'
          }
        },
        {
          name: 'phone-reward',
          label: 'Phone Number',
          placeholder: 'Phone Number',
          // value: this.user?.phone ? this.user.phone : null,
          autocomplete: 'phoneNumberReward',
          type: 'text',
          mask: '###-###-####',
          required: false,
          valid: /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
          errors: {
            invalid: 'Please enter a valid US phone number'
          }
        },
        {
          name: 'address',
          label: 'Address',
          placeholder: 'Address',
          autocomplete: 'address',
          type: 'text',
        },
        {
          name: 'state',
          label: 'State',
          placeholder: 'State',
          autocomplete: 'state',
          type: 'text',
          columns: 2,
        },
        {
          name: 'zipCode',
          label: 'Zip Code',
          placeholder: 'Zip Code',
          autocomplete: 'zipcode',
          type: 'text',
          columns: 2,
        },
        {
          name: 'size',
          label: 'Size',
          placeholder: 'Size',
          autocomplete: 'size',
          type: 'text',
        },
      ]
    },
    hasRedeemSteps() {
      return this.section && this.section.redeem_steps?.length > 0
    },
  },
  methods: {
    submitRedeemable() {
      // WIP! implement redeemable
    }
  }
}
