
  export default {
    name: 'Section',
    props: {
      blok: {
        type: Object,
        default: () => {}
      }
    },
    computed: {
      hasBgImage() {
        return this.blok.bg_image?.filename
      },
      hasBgImageMobile() {
        return this.blok.bg_image_mobile?.filename
      },
      hasTitleImage() {
        return this.blok.title_image?.filename;
      },
    }
  }
