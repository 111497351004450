

export default {
  name: 'Logo',
  props: {
    asset: {
      type: Object,
      default: () => ({
        url: 'https://a.storyblok.com/f/249787/175x39/372988b5fe/your-pie-logo.svg',
        alt: 'YourPie'
      })
    }
  },
  computed: {
    linkTo() {
      return this.$route.path === '/' ? null : '/';
    },
  },
}
