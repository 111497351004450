
import { mapState } from 'vuex'

import SavedAddress from '../SavedAddress'

export default {
  name: 'SavedAddresses',
  components: {
    SavedAddress
  },
  computed: {
    ...mapState([ 'user' ]),
    ...mapState({
      addresses: state => state.members.addresses,
    })
  },
}
