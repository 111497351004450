
  import { mapMutations, mapActions } from 'vuex'

  import LogoAmex from '../../globals/Icon/vectors/LogoAmex.vue'
  import LogoDiscover from '../../globals/Icon/vectors/LogoDiscover'
  import LogoMasterCard from '../../globals/Icon/vectors/LogoMasterCard'
  import LogoVisa from '../../globals/Icon/vectors/LogoVisa'

  export default {
    name: 'SavedCreditCard',
    props: {
      account: {
        type: Object,
        default: () => {}
      },
      selected: {
        type: Boolean,
        default: false
      },
      removeEnabled: {
        type: Boolean,
        default: false
      },
      onSelect: {
        type: Function,
        default: () => {}
      }
    },
    components: {
      LogoAmex,
      LogoDiscover,
      LogoMasterCard,
      LogoVisa
    },
    computed: {
      logo() {
        if (this.account && this.account.type) {
          switch(this.account.type.toLowerCase()) {
            case 'amex':
            case 'american express':
              return 'LogoAmex'
            case 'discover':
              return 'LogoDiscover'
            case 'mastercard':
              return 'LogoMasterCard'
            case 'visa':
              return 'LogoVisa'
            default:
              return null
          }
        }

        return null
      }
    },
    methods: {
      ...mapMutations([ 'setLoading' ]),
      ...mapActions({
        deletePayment: 'members/deletePayments'
      }),
      handleSelect() {
        this.onSelect(this.account)
      },
      removePayment(account) {
        this.setLoading(true)
        this.deletePayment(account.id)
        this.setLoading(false)
      }
    }
  }
