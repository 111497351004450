import { render, staticRenderFns } from "./SignUpLocCard.vue?vue&type=template&id=00f6e226&scoped=true"
import script from "./SignUpLocCard.vue?vue&type=script&lang=js"
export * from "./SignUpLocCard.vue?vue&type=script&lang=js"
import style0 from "./SignUpLocCard.vue?vue&type=style&index=0&id=00f6e226&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00f6e226",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CommonIcon: require('/opt/build/repo/node_modules/@pleinair/bigcolony-vue/components/common/Icon/index.vue').default})
