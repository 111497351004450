
import { mapState } from 'vuex';
import { CartIcon } from '@/components';
export default {
  name: 'signin-button',
  components: {
    CartIcon
  },
  computed: {
    ...mapState([ 'user']),
    ...mapState({
      rewards: state => state.members.rewards,
    }),
  }
}
