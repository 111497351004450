
  export default {
    name: 'banner',
    props: {
      blok: {
        type: Object,
        default: () => {}
      },
      param: String
    },
    computed: {
      hasCtaSlot() {
        return !!this.$slots.cta;
      },
      hasBgImage () {
        return this.blok.bg_image && this.blok.bg_image.filename
      },
      hasBgImageMobile () {
        return this.blok.bg_image_mobile && this.blok.bg_image_mobile.filename
      },
      hasAppButtons () {
        return !this.hasCtaSlot
            && !this.blok.cta.length > 0
            && this.blok.layout === 'app'
      },
    },
    methods: {
      btnClasses(btn) {
        return [
          btn.variant,
          btn.size,
          btn.icon_position
        ];
      },
      scrollToPosition(element) {
        let el = document.querySelector(element).offsetTop - 56;

        window.scrollTo({
          top: el,
          behavior: 'smooth'
        });
        history.pushState(null, null, element)
      },
      handleClick(btn) {
        if (btn.emit_event) {
          this.$emit(btn.emit_event);
          return;
        }
        else if (btn.link?.cached_url && btn.link?.linktype === 'url') {
          window.location.href = btn.link.cached_url
        }
        else if (btn.link?.cached_url) {
          const param = this.param ? `?${this.param}` : ''
          this.$nuxt.$router.push('/' + btn.link.cached_url + param)
        }
        else if (btn.customActionUrl) {
          window.location.href = btn.customActionUrl
        }
      }
    }
  }
