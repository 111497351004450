
import { mapState, mapMutations, mapActions } from 'vuex'
import LocCard from '../../account/signup/SignUpLocCard.vue'

export default {
  name: 'InputDefault',
  components: {
    LocCard
  },
  props: {
    field: {
      type: Object,
      default: {
        name: '',
        label: '',
        type: 'search',
        value: '',
        placeholder: 'Search Locations...',
        // valid: /.*?/,
        touched: false,
        error: null,
        errors: {
          invalid: 'Please select a favorite 2 location.'
        }
      }
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      searchTerm: '',
      searched: false,
      searchError: null,
      providerLocations: null,
      selected: null,
    }
  },
    computed: {
    ...mapState({
      results: state => state.locations.results,
    })
  },
  methods: {
    ...mapMutations([ 'setLoading', 'setErrors' ]),
    ...mapActions({
      addressSearch: 'locations/addressSearch'
    }),
    ...mapActions([ 'sendRequest' ]),
    async searchAddress(term) {
      this.setLoading(true)
      if (term) {
        this.searchError = null
        this.searched = false
        let config = {
          method: 'post',
          url: 'members/options',
          headers: {
            verify: true
          },
          data: {
            zip: term
          }
        }
        let response = await this.sendRequest(config)
        if(response.status === 'ok') {
          this.providerLocations = response.data.locations
          await this.addressSearch(term)
        } else {
          this.setErrors(response.message)
        }
        this.searched = true
      } else {
        this.searchError = 'Please enter a city or zipcode to search'
      }
      this.setLoading(false)
    },
    validate() {
      let valid = this.field.value ? true : false
      this.field.error = valid ? null : this.field.errors.invalid
      return valid
    },
    selectLocation(loc) {
      console.log(loc)
      this.selected = loc.menu_id
      if (this.providerLocations?.length) {
        this.providerLocations.map(l => {
          if (l.loc_id === loc.id) {
            this.field.value = l.id
          }
        })
      }
      this.validate()
    }
  }
}
