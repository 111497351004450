
  export default {
    name: 'page-section',
    props: {
      blok: {
        type: Object,
        default: () => {}
      }
    }
  }
