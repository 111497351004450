
  import '@/node_modules/swiper/swiper-element-bundle.min.js'
  import '~/node_modules/swiper/swiper-bundle.min.css'

  export default {
    name: 'ArticlesSection',
    props: {
      blok: {
        type: Object,
        default: () => {}
      }
    },
    data() {
      return {
        item: {}
      }
    },
    mounted() {},
    computed: {
      swiperOptions() {
        const options = {
          pagination: true,
          'pagination-clickable': true,
          'slides-per-view': '1.15',
          'space-between': '10',
          'centered-slides': true,
          'centered-slides-bounds': true,
        }
        return options
      },
      isMobile() {
        return this.$mq == 'sm' || this.$mq == 'md' ? true : false
      },
    },
    methods: {}
  }
