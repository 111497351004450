
import * as animationData from '~/assets/lottie/loader.json'
import AppContainer from '@/layouts/AppContainer.vue'
import mainMenu from '~/assets/menus/main.json'
import footerMenus from '~/assets/menus/footer.json'
import logos from '~/assets/icons/icons.json'

export default {
  name: 'DefaultLayout',
  components: {
    AppContainer
  },
  computed: {
    lottieData() {
      return animationData.default
    },
    mainMenu() {
      return mainMenu
    },
    footerMenu() {
      return footerMenus
    },
    logos() {
      return logos
    },
    locationIcon() {
      return {
        name: 'location-icon',
        classes: 'loc-pin primary',
      }
    },
    chevron() {
      return {
        name: 'chevron-right-icon',
        classes: 'chevron black',
      }
    },
    rewardsTrophy() {
      return {
        name: 'flying-pie-trophy-icon',
        classes: 'icon',
      }
    }
  },
}
