
import { mapState, mapMutations, mapActions } from 'vuex'

export default {
  name: 'HandoffModeSelector',
  props: {
    handoffModes: {
      type: Array,
      default: () => [
        {
          key: 'delivery',
          icon: 'delivery-icon',
          mobile: 'Delivery',
          desktop: 'Order Delivery',
          classes: 'icon black',
        },
        {
          key: 'pickup',
          icon: 'pickup-icon',
          mobile: 'Pickup',
          desktop: 'Order Pickup',
          classes: 'icon black',
        },
      ]
    },
    color: {
      type: String,
      default: 'primary'
    },
    hideName: Boolean,
  },
  computed: {
    ...mapState([ 'cart' ]),
    ...mapState({
      handoffMode: state => state.order.handoffMode,
      deliveryAddress: state => state.locations.deliveryAddress
    }),
    isMobile() {
      return this.$mq == 'sm' || this.$mq == 'md' ? true : false
    },
  },
  mounted() {
    if (!this.handoffMode) {
      if (this.cart && this.cart.handoff) {
        this.setHandoff(this.cart.handoff)
      } else if (this.$route.query && this.$route.query.section) {
        this.setHandoff(this.$route.query.section)
      } else {
        this.setHandoff('pickup')
      }
      return
    } else {
      if (this.$route.query && this.$route.query.section) {
        this.setHandoff(this.$route.query.section)
      }
    }
  },
  methods: {
    ...mapActions([ 'getCart', 'getDeliveryLocations' ]),
    ...mapActions({
      applyCartHandoff: 'checkout/applyCartHandoff',
    }),
    ...mapMutations([ 'setShowCart' ]),
    ...mapMutations({
      setHandoffMode: 'order/setHandoffMode'
    }),
    isCateringPage() {
      return this.$route.fullPath.includes('catering')
    },
    async setHandoff(key) {
      this.setHandoffMode(key)

      // if (key === 'pickup') {
      //   this.setPickup()
      // }

      // if (key === 'delivery') {
      //   this.setDelivery()
      // }
      this.redirectToMenu()
    },
    setPickup() {
      if (this.cart) {
        this.applyCartHandoff({ handoffMode: 'pickup' })
      }
    },
    async setDelivery() {
      if (this.deliveryAddress) {
        const location = await this.getDeliveryLocations({
          address: this.deliveryAddress.address,
          city: this.deliveryAddress.city,
          zip: this.deliveryAddress.zip,
        })

        if (location === null) {
          return this.redirectToLocationSearch()
        }

        if (this.cart) {
          await this.applyCartHandoff({
            address: this.deliveryAddress.address,
            building: this.deliveryAddress.building,
            city: this.deliveryAddress.city,
            state: this.deliveryAddress.state,
            zip: this.deliveryAddress.zip,
            phone: this.deliveryAddress.phone,
            instructions: this.deliveryAddress.instructions,
            defaul: false,
            handoffMode: 'delivery',
          })

          await this.getCart()
        }
      }

      if (this.cart && this.cart.address === null) {
        return this.redirectToLocationSearch()
      }
    },
    redirectToMenu() {
      // this.$router.push('/menu/9982')
    },
    redirectToLocationSearch() {
      document.body.style.overflow = 'auto'
      this.setShowCart(false)
      this.$router.push(this.isCateringPage() ? '/catering' : '/locations')
    },
  }
}
