
import { mapState } from 'vuex'

import HowToRedeem from '@/components/account/default/elements/HowToRedeem.vue'
import AccountChallengeCard from '@/components/account/default/elements/AccountChallengeCard.vue'

export default {
  name: 'ChallengesSection',
  components: {
    HowToRedeem,
    AccountChallengeCard,
  },
  props: {
    section: Object
  },
  computed: {
    ...mapState({
      challenges: state => state.members.challenges,
    }),
    mockedChallenges() {
      return [
        {
          "id": 60419,
          "name": "Order 1 Chocolate Chip Cookie and get 1 free",
          "description": "Chocolate Chunk Cookie – semi-sweet chocolate chunks with a hint of brown sugar and pure bourbon vanilla.",
          "image_url": "https://olo-images-live.imgix.net/70/70c27cdac3f94b8eb87f0113c23f4115.jpg?auto=format%2Ccompress&q=60&cs=tinysrgb&w=1200&h=800&fit=fill&fm=png32&bg=transparent&s=fd64ae97852d4e92754614c7d6afae4d",
          "start_date": "2023-06-09",
          "end_date": "2024-05-01",
          "progress": [
            {
              "completed_steps": 1,
              "total_steps": 1
            }
          ],
        },
        {
          "id": 60420,
          "name": "Order 1 Cheese Sticks and get 1 free",
          "description": "Breadsticks loaded with mozzarella and Parmesan cheese, seasoned with garlic and oregano, and served with your choice of housemade ranch or marinara dipping sauce. NO ADDITIONS",
          "image_url": "https://olo-images-live.imgix.net/d7/d725fd8644d44b6cadae33db4b84477f.jpg?auto=format%2Ccompress&q=60&cs=tinysrgb&w=1200&h=800&fit=fill&fm=png32&bg=transparent&s=2f8f4fe1bbed7037c70e16e60814a3aa",
          "start_date": "2023-06-09",
          "end_date": "2024-05-01",
          "progress": [
            {
              "completed_steps": 0,
              "total_steps": 1
            }
          ],
        },
        {
          "id": 60421,
          "name": "Order 2 Gelatos and get 1 free",
          "description": "Italian Gelato is made differently than ice cream, with milk instead of cream. Its decadence comes from the rich flavors of all-natural ingredients, not added butterfat. Enjoy!",
          "image_url": "https://olo-images-live.imgix.net/b5/b5e37948535149c0bd05c24457da7c6d.jpg?auto=format%2Ccompress&q=60&cs=tinysrgb&w=1200&h=800&fit=fill&fm=png32&bg=transparent&s=58fcacbb7fe9f1c189e2e2871032071b",
          "start_date": "2023-06-09",
          "end_date": null,
          "progress": [
            {
              "completed_steps": 1,
              "total_steps": 2
            }
          ],
        },
        {
          "id": 60422,
          "name": "Order 3 BYO pastas and get 1 free",
          "description": "Penne pasta with traditional marinara, creamy alfredo or basil pesto, plus your choice of cheese, fresh veggies and protein, baked to a perfect golden brown.",
          "image_url": "https://olo-images-live.imgix.net/0e/0e3b28d703d643f0a2ac3aaa2c668e2d.jpg?auto=format%2Ccompress&q=60&cs=tinysrgb&w=1200&h=800&fit=fill&fm=png32&bg=transparent&s=b874327f235c102e24d9ac33f5f565a1",
          "start_date": null,
          "end_date": "2024-05-01",
          "progress": [
            {
              "completed_steps": 2,
              "total_steps": 3
            }
          ],
        },
        {
          "id": 60423,
          "name": "Order 4 BYO pizzas and get 1 free",
          "description": "Your choice of hand-tossed dough, house-made sauce, cheese, fresh toppings, and protein, cooked to order in our brick oven.",
          "image_url": "https://olo-images-live.imgix.net/15/1544545d5851498ab0faab719a39ab34.png?auto=format%2Ccompress&q=60&cs=tinysrgb&w=1200&h=800&fit=fill&fm=png32&bg=transparent&s=a95448a5c1430c8a1a5eb72357261a96",
          "start_date": null,
          "end_date": null,
          "progress": [
            {
              "completed_steps": 1,
              "total_steps": 4
            }
          ],
        },
      ]
    },
    hasRedeemSteps() {
      return this.section && this.section.redeem_steps?.length > 0
    },
  }
}
