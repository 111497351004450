
import { mapState, mapActions, mapMutations } from 'vuex'

import DefaultForm from '../../../globals/forms/DefaultForm.vue'
import AccountReferral from '../elements/AccountReferral.vue'

export default {
  name: 'AccountInfoSection',
  components: {
    DefaultForm,
    AccountReferral,
  },
  data() {
    return {
      resetFields: [
        // {
        //   name: 'old_password',
        //   label: 'Old Password',
        //   placeholder: 'Old Password',
        //   type: 'password',
        //   valid: /^[a-zA-Z0-9\!\@\#\$\%\^\&\*\(\)\{\}]{8,20}$/,
        //   max: 20,
        //   errors: {
        //     invalid: 'Password must be between 8 and 20 characters.'
        //   }
        // },
        // {
        //   name: 'password',
        //   label: 'New Password',
        //   placeholder: 'New Password',
        //   type: 'password',
        //   valid: /^[a-zA-Z0-9\!\@\#\$\%\^\&\*\(\)\{\}]{8,20}$/,
        //   max: 20,
        //   errors: {
        //     invalid: 'New Password must be between 8 and 20 characters.'
        //   }
        // },
      ],
    }
  },
  computed: {
    ...mapState([ 'token', 'user', 'activeAccountSection' ]),
    isMobile() {
      return this.$mq == 'sm' || this.$mq == 'md' ? true : false
    },
    infoFields() {
      return [
        {
          name: 'email',
          label: 'Email Address',
          placeholder: 'Email Address',
          value: this.user?.email ? this.user.email : null,
          type: 'email',
          required: true,
          disabled: true,
          valid: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          errors: {
            invalid: 'Please enter a valid email address.'
          }
        },
        {
          name: 'first_name',
          label: 'First Name',
          placeholder: 'First Name',
          value: this.user?.first_name ? this.user.first_name : null,
          autocomplete: 'firstName',
          type: 'text',
          required: true,
          valid: /^[A-Za-z\s]*$/,
          errors: {
            invalid: 'Names may only contain letters'
          }
        },
        {
          name: 'last_name',
          label: 'Last Name',
          placeholder: 'Last Name',
          value: this.user?.last_name ? this.user.last_name : null,
          autocomplete: 'lastName',
          type: 'text',
          required: true,
          valid: /^[A-Za-z\s]*$/,
          errors: {
            invalid: 'Names may only contain letters'
          }
        },
        {
          name: 'phone',
          label: 'Phone Number',
          placeholder: 'Phone Number',
          value: this.user?.phone ? this.user.phone : null,
          autocomplete: 'phoneNumber',
          type: 'tel',
          mask: '###-###-####',
          required: false,
          valid: /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
          errors: {
            invalid: 'Please enter a valid US phone number'
          }
        },
        // Punchh does not allow to update the dob
        // {
        //   name: 'dob',
        //   label: 'Date of Birth',
        //   type: 'date-select',
        //   default: this.user?.dob ? this.user.dob : null,
        //   value: this.user?.dob ? this.user.dob : null,
        //   valid: /^\d{4}-\d{2}-\d{2}$/,
        //   required: false,
        //   errors: {
        //     invalid: 'Please enter a valid Date of Birth'
        //   }
        // },
        {
          name: 'optin',
          label: 'Receive email messages',
          message: 'Yes, send me email updates about my rewards and special offers.',
          type: 'checkbox',
          showTerms: false,
          required: false,
          value: this.user?.optin ? true : false,
          errors: {
            invalid: 'Email opt in must be selected.'
          }
        },
        // {
        //   name: 'optin_sms',
        //   label: 'Receive text messages',
        //   message: 'Yes, send me Text Messages about my rewards and special offers. Msg/Data rates may apply. Reoccurring messages may be sent by an automated system. Consent not required. Text ‘Help’ for help, text ‘Stop’ to end anytime.',
        //   type: 'checkbox',
        //   showTerms: false,
        //   required: false,
        //   value: this.user?.optin_sms ? true : false,
        //   errors: {
        //     invalid: 'SMS opt in must be selected.'
        //   }
        // }
      ]
    }
  },
  async beforeMount() {
    if(!this.token) {
      this.$router.push('/account/signin')
    }
  },
  methods: {
    ...mapActions([ 'sendRequest' ]),
    ...mapActions({
      updateUser: 'members/updateMember'
    }),
    ...mapMutations([ 'setLoading', 'setErrors' ]),
    async updateInfo(data) {
      this.setLoading(true)
      data.email = this.user.email

      const success = await this.updateUser(data)
      console.log(success)
      if (success.status === 'ok') {
        this.setErrors('Your Account Info has been updated successfully!')
      } else {
        if (success.errors) {
          const messages = []
          for (const [key, value] of Object.entries(success.errors)) {
            const capKey = this.$utils.capitalize(key.replace(/_/g, ' '))
            messages.push(`${capKey}: ${value}`)
          }
          this.setErrors(messages)
        } else {
          this.setErrors('Please validate all required fields have been filled out')
        }
      }
      this.setLoading(false)
    },
    async resetPassword(data) {
      this.setLoading(true)
      data.email = this.user.email
      let config = {
        method: 'post',
        url: 'members/password',
        headers: {
          verify: true
        },
        data
      }
      config.headers.verify_transition = true

      const response = await this.sendRequest(config)
      if(response.status === 'ok' || response.status === 'validate') {
        this.setErrors('An email with instructions to reset your password has been sent to your email address.')
      } else {
        this.setErrors(response.message)
      }
      this.setLoading(false)
    }
  }
}
