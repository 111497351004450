
export default {
  name: 'InputDefault',
  props: {
    field: {
      type: Object,
      default: {
        name: '',
        label: '',
        type: 'text',
        value: '',
        valid: /.*?/,
        touched: false,
        error: null,
        errors: {
          invalid: 'Please enter a valid value.'
        }
      }
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    validate() {
      if (!this.field.value && !this.field.required) {
        this.field.error = ''
        return true
      }
      const valid = this.field.value
        ? this.field.value.replace(/[- )(]/g,'').match(this.field.valid)
        : false
      this.field.error = valid ? null : this.field.errors.invalid
      return valid
    },
    handleKeyup(e) {
      if(this.field.touched) {
        this.validate()
      }
      if(this.field.max && this.field.value.length > this.field.max) {
        this.field.value = this.field.value.slice(0, -1)
      }
    },
    handleBlur(e) {
      this.field.touched = true
      this.validate()
    }
  }
}
