
import { mapState } from 'vuex'
import AddOffer from '@/components/account/default/elements/AddOffer.vue'
import HowToRedeem from '@/components/account/default/elements/HowToRedeem.vue'
import AccountOfferCard from '@/components/account/default/elements/AccountOfferCard.vue'

export default {
  name: 'RewardOffers',
  components: {
    AddOffer,
    HowToRedeem,
    AccountOfferCard,
  },
  props: {
    section: Object
  },
  computed: {
    ...mapState([ 'token', 'user', 'location', 'activeAccountSection']),
    ...mapState({
      // offers: state => state.members.offers,
      rewards: state => state.members.rewards,
    }),
    // Mock Offers
    offers() {
      return this.rewards
    },
    hasRewards() {
      return this.rewards?.rewards?.length > 0
    },
    hasRedeemSteps() {
      return this.section && this.section.redeem_steps?.length > 0
    },
  }
}
