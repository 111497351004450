
import { mapState } from 'vuex'
import FormField from './FormField.vue'

export default {
  name: 'DefaultForm',
  components: {
    FormField
  },
  props: {
    formTitle: {
      type: String,
      default: null
    },
    columns: {
      type: Number,
      default: 1
    },
    cta: {
      type: String,
      default: 'Submit'
    },
    cancelCta: {
      type: String,
      default: null
    },
    hideCta: {
      type: Boolean,
      default: false
    },
    fields: {
      type: Array,
      default: []
    },
    message: {
      type: String,
      default: null
    },
    onSubmit: {
      type: Function,
      default: () => {}
    },
    onCancel: {
      type: Function,
      default: () => {}
    },
    submitIcon: {
      type: Boolean,
      default: false
    },
    ctaClasses: {
      type: String,
      default: 'btn primary'
    }
  },
  computed: {
    ...mapState([ 'loading' ])
  },
  data() {
    let formFields = []
    this.fields.map(f => {
      let field = this.$utils.copy(f)
      field.valid = f.valid
      if(field.type !== 'link') {
        if(typeof field.value === 'undefined') {
          field.value = ''
        }
        if(typeof field.touched === 'undefined') {
          field.touched = false
        }
        if(typeof field.error === 'undefined') {
          field.error = null
        }
      }
      formFields.push(field)
    })
    return {
      formFields,
      pMatch: null,
    }
  },
  methods: {
    validate(t) {
      let valid = false
      switch(t.type) {
        case 'link':
          return true
        case 'loc-search':
          if (t.required) {
            valid = t.value ? true : false
            t.error = valid ? null : t.errors.invalid
          } else {
            valid = true
          }
          return valid
        case 'checkbox':
          if (t.required) {
            valid = t.value
          } else {
            valid = true
          }
          t.error = valid ? null : t.errors.invalid
          return valid
        case 'password':
          if (t.name === 'confirm-password') {
            valid = this.pMatch && t.value === this.pMatch ? true : false
            t.error = valid ? null : t.errors.invalid
            return valid
          } else {
            this.pMatch = t.value
            valid = t.value.match(t.valid)
            t.error = valid ? null : t.errors.invalid
            return valid
          }
        case 'tel':
          valid = t.value.replace(/[- )(]/g,'').match(t.valid)
          t.error = valid ? null : t.errors.invalid
          return valid
        default:
          if (!t.value && !t.required) {
            t.error = ''
            return true
          }
          valid = t.value.match(t.valid)
          t.error = valid ? null : t.errors.invalid
          return valid
      }
    },
    handleSubmit() {
      let data = {}
      this.formFields.map(f => {
        if(this.validate(f) && f.name !== undefined) {
          if (f.type === 'tel') {
            data[f.name] = f.value.replace(/[- )(]/g,'')
          } else {
            data[f.name] = f.value
          }
        }
      })
      if(!this.formFields.find(f => f.error)) {
        this.onSubmit(data)
      }
    }
  }
}
