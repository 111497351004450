import { render, staticRenderFns } from "./RewardsSection.vue?vue&type=template&id=3e8b80ac"
import script from "./RewardsSection.vue?vue&type=script&lang=js"
export * from "./RewardsSection.vue?vue&type=script&lang=js"
import style0 from "./RewardsSection.vue?vue&type=style&index=0&id=3e8b80ac&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {StoryblokButton: require('/opt/build/repo/node_modules/@pleinair/bigcolony-vue/components/storyblok/Button.vue').default})
