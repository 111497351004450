
import { mapState, mapMutations } from 'vuex'
import { LocalIcons, MainMenu, MobileMenu } from '@/assets';

import { 
  MainNav, 
  Logo,
  MenuIcon,
  MobileNav, 
  SigninButton,
  HandoffModeSelector,
} from '@/components'


export default {
  name: 'SiteHeader',
  components: {
    MainNav,
    Logo,
    MenuIcon,
    MobileNav,
    SigninButton,
    HandoffModeSelector,
  },
  data() {
    return {
      handoffModes: [],
      logoDesktop: LocalIcons['logo'],
      logoMobile: LocalIcons['logo-mobile'],
      menu: MainMenu,
      mobileMenu: MobileMenu,
    }
  },
  computed: {
    ...mapState([ 'appInfo', 'user', 'location', 'showNav', 'order', 'cart' ]),
    ...mapState({
      rewards: state => state.members.rewards,
    }),
    isMobile() {
      return this.$mq == 'sm' || this.$mq == 'md' ? true : false
    },
    appHandoffModes() {
      return this.appInfo.order['handoff_modes_main_nav']
    },
    locationHandoffModes() {
      if (this.location) {
        return this.$utils.locationHandoffModes(this.location, this.appHandoffModes)
      }
    },
    hasCartProducts() {
      return this.cart && this.cart.products?.length > 0
    }
  },
  methods: {
    ...mapMutations({
      setHandoffMode: 'order/setHandoffMode'
    }),
    goToReserveTable() {
      this.setHandoffMode('reservation')
      this.$nuxt.$router.push('/locations')
    },
  }
}
