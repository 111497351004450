
  export default {
    name: 'offer-card',
    props: {
      blok: Object,
    },
    computed: {
      image() {
        if (this.blok.image && this.blok.image.filename) {
          return this.blok.image.filename
        }
        if (this.blok.image) {
          return this.blok.image
        }
        return ''
      },
      title() {
        if (this.blok.title) {
          return this.blok.title
        }
        if (this.blok.label) {
          return this.blok.label
        }
        return ''
      },
      subtitle() {
        if (this.blok.subtitle) {
          return this.blok.subtitle
        }
        if (this.blok.description) {
          return this.blok.description
        }
        return ''
      },
    }
  }
