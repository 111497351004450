
export default {
  name: 'PhoneInfo',
  props: {
    data: Object,
  },
  computed: {
    phone() {
      if (!this.data.phone) return;
      let p = `${this.data?.phone}`
      if (p.length === 10) {
        return `(${p.substring(0, 3)}) ${p.substring(3, 6)}-${p.substring(6, 10)}`
      } else {
        return p
      }
    },
    phoneLink() {
      if (!this.data.phone) return;
      return `tel:${this.data.phone}`
    },
  },
}
