
  import { mapState } from 'vuex'

  export default {
    computed: {
      ...mapState([ 'showNav' ]),
    },
    methods: {
      toggleNav() {
        document.body.style.overflow = !this.showNav ? 'hidden' : 'auto'
        this.$store.commit('setShowNav', !this.showNav)
      }
    }
  }
