
import { mapState, mapActions } from 'vuex'
import HowToRedeem from '../../elements/HowToRedeem.vue'
import AccountOfferCard from '../../elements/AccountOfferCard.vue'

export default {
  name: 'RewardSubscriptions',
  components: {
    HowToRedeem,
    AccountOfferCard
  },
  props: {
    sections: {
      type: Array,
      default: () => [
        {
          name: 'Offers',
          section: 'offers',
        },
        {
          name: 'Rewards',
          section: 'rewards',
        },
      ]
    }
  },
  beforeMount() {
    this.getSubscriptions()
    this.getAvailableSubscriptions()
  },
  computed: {
    ...mapState([ 'token', 'user', 'location', 'activeAccountSection']),
    ...mapState({
      subscription: state => state.subscriptions.subscription,
      availableSubscriptions: state => state.subscriptions.availableSubscriptions,
    })
  },
  methods: {
    ...mapActions({
      getSubscriptions: 'subscriptions/getSubscriptions',
      getAvailableSubscriptions: 'subscriptions/getAvailableSubscriptions'
    })
  }
}
