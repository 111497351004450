
import { mapState, mapMutations, mapActions } from 'vuex'

import LocationPhoneInfo from '../../locations/search/elements/PhoneInfo.vue'
import LocationAddressInfo from '../../locations/search/elements/AddressInfo.vue'

export default {
  name: 'SavedAddress',
  components: {
    LocationPhoneInfo,
    LocationAddressInfo,
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    removeEnabled: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    ...mapState([ 'location' ])
  },
  methods: {
    ...mapActions([ 'getCart', 'getDeliveryLocations' ]),
    ...mapActions({
      applyCartHandoff: 'checkout/applyCartHandoff',
      deleteAddresses: 'members/deleteAddresses',
      getAddresses: 'members/getAddresses',
    }),
    ...mapMutations([ 'setLoading' ]),
    ...mapMutations({
      setDeliveryAddress: 'locations/setDeliveryAddress',
    }),
    async chooseAddress() {
      if (this.removeEnabled) return

      const location = await this.getDeliveryLocations({
        address: this.data.address,
        city: this.data.city,
        zip: this.data.zip,
      })

      if (location === null) return

      this.setDeliveryAddress({
        address: this.data.address,
        building: this.data.building,
        city: this.data.city,
        state: this.data.state,
        zip: this.data.zip,
        phone: this.data.phone,
        instructions: this.data.instructions,
      })

      if (this.cart) {
        await this.applyCartHandoff({
          address: this.data.address,
          building: this.data.building,
          city: this.data.city,
          state: this.data.state,
          zip: this.data.zip,
          phone: this.data.phone,
          instructions: this.data.instructions,
          defaul: false,
          handoffMode: 'delivery',
        })

        await this.getCart()
      }
    },
    async removeAddress() {
      this.setLoading(true)
      const response = await this.deleteAddresses(this.data.id)
      if (response) {
        this.getAddresses()
      }
      this.setLoading(false)
    },
  }
}
