
import { mapState, mapActions } from 'vuex'
import {
  AccountBody,
  AccountHeader,
  AccountNav,
} from '@/components';
import AccountReferral from '../elements/AccountReferral.vue'

export default {
  name: 'AccountViewDefault',
  components: {
    AccountHeader,
    AccountBody,
    AccountNav,
    AccountReferral,
  },
  props: {
    config: {
      type: Object,
      default: () => {
        return {
          provider: 'qu',
          placement: 'account',
          header: 'Account',
          welcome_message: 'Welcome Back,',
          menu: [
            {
              name: 'Personal Information', // Used in acconut nav component to display section names, can be changed across projects
              section: 'user-info', // Used to conditionally show account views, should not be changed across projects
              icon: 'location-icon',
              active_class: 'icon white',
              default_class: 'icon black'
            },
            {
              name: 'Order History',
              section: 'order-history',
              icon: 'location-icon',
              active_class: 'icon white',
              default_class: 'icon black'
            },
            {
              name: 'Payment Methods',
              section: 'payments',
              icon: 'location-icon',
              active_class: 'icon white',
              default_class: 'icon black'
            },
            {
              name: 'Available Offers',
              section: 'offers',
              icon: 'location-icon',
              active_class: 'icon white',
              default_class: 'icon black'
            },
            {
              name: 'Rewards',
              section: 'rewards',
              icon: 'rewards-icon',
              active_class: 'icon white',
              default_class: 'icon black'
            },
            {
              name: 'Locations',
              section: 'locations',
              icon: 'location-icon',
              active_class: 'icon white',
              default_class: 'icon black'
            },
          ]
        }
      }
    },
    onEvent: Function,
  },
  async beforeMount() {
    if (this.user) {
      await Promise.all([
        this.getOffers(),
        this.getRewards(),
        this.getPayments(),
        this.getChallenges(),
        this.getRedeemables(),
        this.getMarketplace(),
      ])
    }
  },
  computed: {
    ...mapState([ 'token', 'user' ]),
    isMobile() {
      return this.$mq == 'sm' || this.$mq == 'md' ? true : false
    },
  },
  methods: {
    ...mapActions({
      getOffers: 'members/getOffers',
      getRewards: 'members/getRewards',
      getPayments: 'members/getPayments',
      getChallenges: 'members/getChallenges',
      getRedeemables: 'members/getRedeemables',
      getMarketplace: 'members/getMarketplace',
    }),
  }
}
