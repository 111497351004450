
export default {
  name: 'FeatureList',
  props: {
    blok: {
      type: Object,
      default: () => ({}),
    },
  },
};
