
export default {
  name: 'AddressInfo',
  props: {
    data: Object,
  },
  computed: {
    mapLink() {
      if(this.data && this.data.lat && this.data.lng) {
        return `https://maps.google.com/?q=${this.data.lat},${this.data.lng}`
      }
    }
  }
}
