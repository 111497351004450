
import NavLink from './NavLink'
import { mapState } from 'vuex'

export default {
  name: 'MainNav',
  components: {
    NavLink
  },
  props: {
    menu: Array,
  },
  computed: {
    ...mapState([ 'appInfo', 'user' ])
  }
}
