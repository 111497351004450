
import { mapState, mapMutations, mapActions } from 'vuex'
import moment from 'moment'

export default {
  name: 'OrderCard',
  props: {
    order: {
      Type: Object,
      Default: {}
    }
  },
  data() {
    return {
      showTotals: null,
    }
  },
  computed: {
    ...mapState(['location']),
    ...mapState({
      locs: state => state.locations
    }),
    hasSlotDate() {
      return false
    },
    hasSlotToggle() {
      return false
    },
    detailIcon() {
      switch(this.order.handoff) {
        case 'pickup':
          return 'pickup-icon'
        case 'delivery':
          return 'delivery-icon'
        case 'curbside':
          return 'curbside-icon'
        default: break;
      }
    },
  },
  methods: {
    ...mapMutations([ 'setShowCart', 'setCart', 'setToken', 'setLoading', 'setErrors', 'setLocation' ]),
    ...mapActions([ 'sendRequest' ]),
    getDate(date) {
      if(date) {
        return moment(date, 'YYYYMMDD HH:mm').format('ddd MMM D, YYYY');
      }
    },
    getChoices(choices) {
      if(!choices && !Array.isArray(choices)) return;
      return choices.map((choice) => choice.name).join('<span>•</span>');
    },
    formatPrice(value) {
      if(typeof value === 'number' && isFinite(value)) {
        return `$${value.toFixed(2)}`
      }
    },
    async reorder(order) {
      if(!this.location || !this.location.address) {
        const locationsByMenuId = {}
        const all = this.locs.all
        for (let i = 0; i < all.length; i++) {
          let location = all[i]
          let cities = location.cities
          for (let j = 0; j < cities.length; j++) {
            let city = cities[j]
            let cityLocations = city.locations
            for(let k = 0; k < cityLocations.length; k++) {
              let loc = cityLocations[k]
              locationsByMenuId[loc.menu_id] = loc
            }
          }
        }
        this.setLocation(locationsByMenuId[order.menu_id])
      }
      
      if (order.id) {
        this.setLoading(true)
        const config = {
          method: 'post',
          url: 'orders',
          data: {
            id: order.id
          }
        }
        const response = await this.sendRequest(config)
        if(response.status === 'ok') {
          this.setCart(response.data)
          if (response.data.token) {
            this.setToken(response.data.token)
          }
          document.body.style.overflow = 'hidden'
          this.setShowCart(true)
        } else {
          this.setErrors(response.message)
        }
        this.setLoading(false)
      }
    },
    goToOrder(id) {
      this.$router.push(`order/confirm/${id}`);
    }
  }
}
