
  import '@/node_modules/swiper/swiper-element-bundle.min.js'

  export default {
    name: 'SwiperCarousel',
    props: {
      options: Object
    },
    data() {
      return {}
    },
    mounted() {},
    computed: {},
    methods: {}
  }
