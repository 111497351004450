
import moment from 'moment'
import { mapState } from 'vuex'

import Icon from '@pleinair/bigcolony-vue/components/common/Icon/index.vue'

export default {
  components: { Icon },
  props: {
    challenge: {
      type: Object,
      default: {}
    },
    cta: {
      type: String,
      default: 'primary'
    }
  },
  computed: {
    ...mapState([ 'location' ]),
    showChallenge() {
      return true
    },
    complete() {
      return this.progressObj && this.progressObj.total_steps > 0 && this.progressObj.total_steps === this.progressObj.completed_steps
    },
    timing() {
      if (this.complete) {
        return 'Completed!'
      }

      if (this.challenge.start_date && this.challenge.end_date) {
        return `Starts ${moment(this.challenge.start_date, 'YYYYMMDD HH:mm').format('MM/DD/YYYY')} | Ends ${moment(this.challenge.end_date, 'YYYYMMDD HH:mm').format('MM/DD/YYYY')}`
      }
      if (this.challenge.start_date && this.challenge.end_date === null) {
        return ''
      }
      if (this.challenge.start_date === null && this.challenge.end_date) {
        return `Ends ${moment(this.challenge.end_date, 'YYYYMMDD HH:mm').format('MMMM Do YYYY')}`
      }
      if (this.challenge.start_date === null && this.challenge.end_date === null) {
        return 'Act Fast!'
      }
      return ''
    },
    progressObj() {
      if (this.challenge.progress && this.challenge.progress.length > 0) {
        return this.challenge.progress[0]
      }
      return {}
    },
    progress() {
      if (this.challenge.progress && this.challenge.progress.length > 0) {
        const progress = this.challenge.progress[0]
        const progressList = []
        let count = 0
        while (count < progress.total_steps) {
          progressList.push({
            step: count,
            checked: count < progress.completed_steps ? true : false
          })
          count ++
        }
        return progressList
      }
      return false
    },
    img() {
      if (this.challenge.image_url) {
        return this.challenge.image_url
      }
      return ''
    }
  },
  methods: {
    redeemReward() {
      console.log('test redemption')
    }
  }
}
