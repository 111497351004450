
import { mapState } from 'vuex'
import SavedCreditCard from '@/components/globals/SavedCreditCard/index.vue'

export default {
  name: 'AccountPaymentsSection',
  components: {
    SavedCreditCard
  },
  computed: {
    ...mapState([ 'token', 'user', 'location', 'activeAccountSection']),
    ...mapState({
      payments: state => state.members.payments,
      locs: state => state.locations.all
    }),
    hasSavedPayments() {
      return this.payments && this.payments.length > 0
    }
  }
}
