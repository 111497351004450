
import { mapState } from 'vuex'
import HowToRedeem from '../elements/HowToRedeem.vue'
import AccountOfferCard from '../elements/AccountOfferCard.vue'

export default {
  name: 'AccountRewardsSingleSection',
  components: {
    HowToRedeem,
    AccountOfferCard
  },
  computed: {
    ...mapState([ 'token', 'user', 'location', 'activeAccountSection']),
    ...mapState({
      rewards: state => state.members.rewards,
    }),
    redeemSteps() {
      return [
        {
          title: 'Pick A Location',
          body: 'Tincidunt ac vestibulum quis dolor turpis sit. Mollis at viverra odio.'
        },
        {
          title: 'Start Your Order',
          body: 'Tincidunt ac vestibulum quis dolor turpis sit. Mollis at viverra odio.'
        },
        {
          title: 'Add to Your Wallet',
          body: 'Tincidunt ac vestibulum quis dolor turpis sit. Mollis at viverra odio.'
        },
        {
          title: 'Redeem At Checkout',
          body: 'Tincidunt ac vestibulum quis dolor turpis sit. Mollis at viverra odio.'
        },
      ]
    }
  }
}
