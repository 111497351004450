
import { mapState, mapActions } from 'vuex'

export default {
  name: 'AccountHeader',
  props: {
    header: {
      type: String,
      default: 'Account'
    },
    welcome: {
      type: String,
      default: 'Welcome Back, '
    }
  },
  computed: {
    ...mapState([ 'token', 'user' ]),
    userFirstName() {
      return this.user.first_name
    }
  },
  async beforeMount() {
    if(!this.token) {
      this.$router.push('/account/signin')
    }
  },
  methods: {
    ...mapActions([ 'logoutUser' ]),
    async logout() {
      const result = await this.logoutUser()
      if(result) {
        this.$router.push('/account/signin')
      }
    }
  }
}
