
import RewardCard from './RewardCard.vue';
export default {
  name: 'rewards-section',
  components: { RewardCard },
  props: {
    blok: {
      type: Object,
      default: () => {}
    }
  }
}
