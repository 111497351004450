
import { mapState, mapMutations } from 'vuex'

export default {
  name: 'SiteFooter',
  computed: {
    ...mapState([ 'location' ]),
    showSiteOverlay() {
      if (this.isCheckoutPage || this.isMenuPage || this.is404) {
        return false
      }
      return true
    },
    isCheckoutPage() {
      return this.$route.fullPath.includes('checkout')
    },
    isMenuPage() {
      return this.$route.fullPath.includes('menu')
    },
    is404() {
      return this.$route.fullPath.includes('404')
    },
  },
  methods: {
    ...mapMutations([ 'setLocation' ]),
    ...mapMutations({
      setHandoffMode: 'order/setHandoffMode'
    }),
    goToReserveTable() {
      this.setHandoffMode('reservation')
      this.$nuxt.$router.push('/locations')
    },
    goToOrder() {
      const to = this.location && this.location.menu_id
          ? `/menu/${this.location.menu_id}`
          : `/locations`
      this.$router.push(to);
    }
  }
}
