
import { mapState } from "vuex";
import { LocalIcons, FooterMenu } from "@/assets";
import Logo from "@/components/globals/Logo.vue";

export default {
  name: "SiteFooter",
  components: { Logo },
  computed: {
    ...mapState(["appInfo"]),
    appVersion() {
      const appVersion = process.env.APP_VERSION || "";
      const libraryVersion = process.env.LIBRARY_VERSION || "";
      console.info("Library version:", `v${libraryVersion}`);

      return appVersion ? `v${appVersion}` : "";
    },
    footerLogo() {
      return LocalIcons["logo-footer"];
    },
    menu() {
      return FooterMenu.nav;
    },
    pages() {
      return FooterMenu.pages;
    },
    social() {
      return FooterMenu.social;
    },
  },
  methods: {
    isInternalLink(link) {
      if (link.includes("pdf")) {
        return false;
      }

      const internalLinkRegex = /^\/(?!\/)/;
      return internalLinkRegex.test(link);
    },
  },
};
