
import moment from 'moment'
import { mapState } from 'vuex'

export default {
  props: {
    offer: {
      type: Object,
      default: {}
    },
    cta: {
      type: String,
      default: 'primary'
    }
  },
  computed: {
    ...mapState([ 'location' ]),
    expires() {
      if (this.offer.expires) {
        return moment(this.offer.expires, 'YYYYMMDD HH:mm').format('MM/DD/YYYY')
      }
    },
    img() {
      if (this.offer.image) {
        if (this.offer.image.filename) return this.offer.image.filename
        return this.offer.image
      }
      return ''
    },
    title() {
      if (this.offer && this.offer.label) {
        return this.offer.label
      }
      if (this.offer && this.offer.name) {
        return this.offer.name
      }
      return ''
    },
    points() {
      if (this.offer && this.offer.points) {
        return this.offer.points
      }
      if (this.offer && this.offer.points_required_to_redeem) {
        return this.offer.points_required_to_redeem
      }
      return 0
    }
  },
  methods: {
    redeemReward() {
      console.log('test redemption')
    }
  }
}
