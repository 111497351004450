
import { mapState, mapActions } from 'vuex'

import LocationPhoneInfo from '../../locations/search/elements/PhoneInfo.vue'
import LocationAddressInfo from '../../locations/search/elements/AddressInfo.vue'
import LocationOperationInfo from '../../locations/search/elements/OperationInfo.vue'

export default {
  name: 'SignUpLocationCard',
  components: {
    LocationPhoneInfo,
    LocationAddressInfo,
    LocationOperationInfo,
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    selectLocation: {
      type: Function,
      default: () => {}
    },
    selected: Boolean,
    isAccountProfile: Boolean,
  },
  computed: {
    ...mapState({
      favLocs: state => state.members.favLocs,
    }),
    services() {
      const services = []
      if (this.data.services) {
        if (this.data.services.curbside) {
          services.push('Curbside')
        }
        if (this.data.services.delivery) {
          services.push('Delivery')
        }
        if (this.data.services.pickup) {
          services.push('In-store pickup')
        }
        if (this.data.services.dinein) {
          services.push('Dine-in')
        }
      }
      return services
    },
  },
  methods: {
    ...mapActions({
      saveFavLoc: 'members/saveFavLoc',
      deleteFavLoc: 'members/deleteFavLoc',
    }),
    async favorite() {
      const favLocFound = this.favLocs.find(l => l.id === this.data.id)
      if (favLocFound){
        await this.deleteFavLoc(this.data.menu_id)
      } else {
        await this.saveFavLoc(this.data.menu_id)
      }
    },
    select() {
      this.selectLocation(this.data)
    }
  },
}
