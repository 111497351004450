
import { mapState } from 'vuex'

export default {
  name: 'AccountReferral',
  computed: {
    ...mapState([ 'user' ]),
  },
  data() {
    return {
      animationCopy: false,
      animationCopied: false,
    }
  },
  methods: {
    copyCode() {
      try {
        navigator.clipboard.writeText(this.user.referral_code)
        this.animationCopy = true
        setTimeout(() => {
          this.animationCopied = true
        }, 1000)

        // fades the Applied state
        setTimeout(() => {
          this.animationCopied = false
          setTimeout(() => {
            this.animationCopy = false
          }, 1000)
        }, 3000)
      } catch(err) {
        //
      }
    }
  }
}
