import { render, staticRenderFns } from "./Default.vue?vue&type=template&id=04665660"
import script from "./Default.vue?vue&type=script&lang=js"
export * from "./Default.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AccountHeader: require('/opt/build/repo/node_modules/@pleinair/bigcolony-vue/components/account/default/elements/AccountHeader.vue').default,AccountReferral: require('/opt/build/repo/components/account/default/elements/AccountReferral.vue').default,AccountNav: require('/opt/build/repo/node_modules/@pleinair/bigcolony-vue/components/account/default/elements/AccountNav.vue').default,AccountBody: require('/opt/build/repo/node_modules/@pleinair/bigcolony-vue/components/account/default/elements/AccountBody.vue').default})
