import Vue from 'vue'

// Google Auth
// import GAuth from 'vue-google-oauth2'

if (process.env.GOOGLE_CLIENT_ID && process.client) {
    const gauthOption = {
    clientId: process.env.GOOGLE_CLIENT_ID,
    scope: 'profile email',
    prompt: 'select_account'
  }
  Vue.use(GAuth, gauthOption)
}

// Apple Signin
import VueAppleSignin from 'vue-apple-signin';

if (process.env.APPLE_CLIENT_ID && process.client) {
  Vue.use(VueAppleSignin, {
    clientId: process.env.APPLE_CLIENT_ID,
    scope: 'name email',
    redirectURI: `${window.location.origin}/oauthcallback`,
    // state: process.env.APPLE_STATE
  })
}
