
import { mapState, mapActions } from 'vuex'
import { SavedAddress } from '@/components'
import FaveLoc from '../../signup/SignUpLocCard.vue'

export default {
  name: 'AccountLocationsSection',
  components: {
    SavedAddress,
    FaveLoc
  },
  beforeMount() {
    this.getAddresses()
    this.getFavLocs()
  },
  computed: {
    ...mapState([ 'token', 'user', 'location', 'activeAccountSection']),
    ...mapState({
      favLocs: state => state.members.favLocs,
      addresses: state => state.members.addresses,
    })
  },
  methods: {
    ...mapActions({
      getAddresses: 'members/getAddresses',
      getFavLocs: 'members/getFavLocs'
    }),
    selectLocation(ev) {
      // do nothing
      console.log(ev);
    }
  }
}
