
import { mapState, mapActions, mapMutations } from 'vuex'
import { Icon } from '@/components'

export default {
  name: 'AccountNav',
  components: {
    Icon
  },
  computed: {
    ...mapState([ 'token', 'user', 'account', 'activeAccountSection' ]),
    isMobile() {
      return this.$mq === 'sm' ? true : false
    }
  },
  props: {
    menu: {
      type: Array,
      default: () => []
    }
  },
  async mounted() {
    if (this.$route.query?.section) {
      this.handleNavClik(this.$route.query?.section)
    } else {
      this.handleNavClik(this.menu[0].section)
    }
    console.log(this.activeAccountSection)
  },
  methods: {
    ...mapActions([ 'logoutUser' ]),
    ...mapMutations([ 'setActiveAccountSection' ]),
    async logout() {
      const result = await this.logoutUser()
      if(result) {
        this.$router.push('/account/signin')
      }
    },
    handleNavClik(section) {
      this.$router.push({ query: { section: section }})
      this.setActiveAccountSection(section)
    },
  }
}
