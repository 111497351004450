
import * as animationData from '~/assets/lottie/loader.json'
import AppContainer from '@/layouts/AppContainer.vue';

export default {
  name: 'AppLayout',
  components: {
    AppContainer
  },
  computed: {
    lottieData() {
      return animationData.default
    }
  },
}
