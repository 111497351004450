
import { mapActions, mapMutations } from 'vuex'
import { Icon } from '@/components'

export default {
  name: 'AddOffer',
  components: {
    Icon
  },
  data() {
    return {
      code:'',
      response: null,
    }
  },
  methods: {
    ...mapActions({
      redeemReward: 'members/redeemReward'
    }),
    ...mapMutations([ 'setLoading' ]),
    async redeem() {
      this.setLoading(true)
      this.response = await this.redeemReward(this.code)
      console.log('test redeem function: ', this.code, this.response)
      this.setLoading(false)
    }
  }
}
