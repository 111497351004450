import { render, staticRenderFns } from "./AccountBody.vue?vue&type=template&id=6fb441b0"
import script from "./AccountBody.vue?vue&type=script&lang=js"
export * from "./AccountBody.vue?vue&type=script&lang=js"
import style0 from "./AccountBody.vue?vue&type=style&index=0&id=6fb441b0&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AccountInfoSection: require('/opt/build/repo/components/account/default/sections/AccountInfoSection.vue').default,AccountHistorySection: require('/opt/build/repo/components/account/default/sections/AccountHistorySection.vue').default,AccountPaymentsSection: require('/opt/build/repo/components/account/default/sections/AccountPaymentsSection.vue').default})
