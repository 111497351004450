
import { mapState, mapMutations } from 'vuex'

import NavLink from './NavLink'

export default {
  name: 'MainNav',
  components: {
    NavLink,
  },
  props: {
    logo: Object,
    menu: Array,
    icon: String,
  },
  computed: {
    ...mapState([ 'appInfo', 'user', 'showNav']),
    ...mapState({
      rewards: state => state.members.rewards,
    }),
    isHomePage() {
      return this.$route.path === '/'
    },
  },
  methods: {
    ...mapMutations([ 'setActiveAccountSection' ]),
    goHome() {
      this.toggleNav()
      return this.$router.push('/')
    },
    goAccountOffers() {
      this.toggleNav()
      this.setActiveAccountSection('offers')
      return this.$router.push('/account?section=offers')
    },
    goAccountProfile() {
      this.toggleNav()
      this.setActiveAccountSection('user-info')
      return this.$router.push('/account?section=user-info')
    },
    goOrder() {
      this.toggleNav()
      return this.$router.push('/menu')
    },
    toggleNav() {
      document.body.style.overflow = !this.showNav ? 'hidden' : 'auto'
      this.$store.commit('setShowNav', !this.showNav)
    },
  },
}
