
import { mapState } from 'vuex'
import HowToRedeem from '@/components/account/default/elements/HowToRedeem.vue'
import AccountOfferCard from '@/components/account/default/elements/AccountOfferCard.vue'
import RewardOffers from './RewardOffers.vue'
import ChallengesSection from './ChallengesSection.vue'
import AccountRewardRedeem from './AccountRewardRedeem.vue'
import RewardSubscriptions from './RewardSubscriptions.vue'

export default {
  name: 'AccountRewardsSection',
  components: {
    HowToRedeem,
    AccountOfferCard,
    RewardOffers,
    ChallengesSection,
    AccountRewardRedeem,
    RewardSubscriptions
  },
  props: {
    sections: {
      type: Array,
      default: () => [
        {
          name: 'Example',
          section: 'redeem',
        },
      ]
    }
  },
  data() {
    return {
      activeSection: this.sections[0].section,
      activeSectionObject: this.sections[0],
    }
  },
  computed: {
    ...mapState([ 'token', 'user', 'location', 'activeAccountSection']),
    ...mapState({
      rewards: state => state.members.rewards,
    })
  }
}
