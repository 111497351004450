
import { mapState, mapActions } from 'vuex'
import OrderCard from '@/components/order/OrderCard.vue';

export default {
  name: 'AccountHistorySection',
  components: {
    OrderCard
  },
  computed: {
    ...mapState([ 'token', 'user', 'activeAccountSection', 'orderHistory' ]),
    hasOrders() {
      return this.orderHistory && this.orderHistory.length > 0
    },
  },
  methods: {
    ...mapActions([ 'getOrderHistory' ]),
  },
  async beforeMount() {
    await this.getOrderHistory()
  },
}
