
import moment from 'moment'

export default {
  name: 'OperationInfo',
  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    separator: {
      type: String,
      default: '•'
    },
  },
  data() {
    return {
      tomorrowDay: new Date().getDay() + 1,
      currentDay: new Date().getDay(),
      currentHour: new Date().getHours(),
      daysOfWeek: ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'],
    }
  },
  computed: {
    services() {
      const hasPickup = this.data.services.pickup;
      const hasDelivery = this.data.services.delivery;
      
      let services = '';
      if (hasPickup && hasDelivery) {
        services = 'Delivery & Pickup';
      } else if (hasPickup) {
        services = 'Pickup';
      } else if (hasDelivery) {
        services = 'Delivery';
      }

      return services;
    },
    todayOpeningHour() {
      return this.formatTime(this.getDayOpeningHour(this.currentDay)?.open)
    },
    todayCloseningHour() {
      return this.formatTime(this.getDayOpeningHour(this.currentDay)?.close)
    },
    tomorrowOpeningHour() {
      return this.formatTime(this.getDayOpeningHour(this.tomorrowDay)?.open)
    },
    isClosing () {
      const currentHour = parseInt(this.currentHour)
      const closingHour = parseInt(this.getDayOpeningHour(this.currentDay)?.close.split(':')[0])
      return currentHour === closingHour - 1
    },
    isOpening () {
      if (!this.getDayOpeningHour(this.currentDay)) return false

      const currentHour = parseInt(this.currentHour)
      const openingHour = parseInt(this.getDayOpeningHour(this.currentDay).open.split(':')[0])
      return currentHour === openingHour - 1
    },
    openingTime() {
      const currentHour = parseInt(this.currentHour)
      const closingHour = parseInt(this.getDayOpeningHour(this.currentDay)?.close.split(':')[0])
      if (currentHour > closingHour) {
        return this.tomorrowOpeningHour
      } else {
        return this.todayOpeningHour
      }
    },
  },
  methods: {
    formatTime(time) {
      return moment(time, 'HH:mm').format('h:mm A');
    },
    getDayOpeningHour(day) {
      if (this.data?.hours) {
        return this.data?.hours.find(
          (hour) => hour.day === this.daysOfWeek[day]
        );
      }
    }
  },
} 
