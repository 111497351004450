
import { mapState } from 'vuex'
import AccountInfoSection from '@/components/account/default/sections/AccountInfoSection.vue'
import AccountHistorySection from '@/components/account/default/sections/AccountHistorySection.vue'
import AccountPaymentsSection from '@/components/account/default/sections/AccountPaymentsSection.vue'
import AccountOffersSection from '@/components/account/default/sections/AccountOffersSection.vue'
import AccountRewardsSingleSection from '@/components/account/default/sections/AccountRewardsSingleSection.vue'
import AccountRewardsSection from '@/components/account/default/sections/AccountRewardsSection/index.vue'
import AccountLocationsSection from '@/components/account/default/sections/AccountLocationsSection.vue'

export default {
  name: 'AccountBody',
  components: {
    AccountInfoSection,
    AccountHistorySection,
    AccountPaymentsSection,
    AccountOffersSection,
    AccountRewardsSingleSection,
    AccountRewardsSection,
    AccountLocationsSection,
  },
  props: {
    menu: {
      type: Array,
      default: []
    }
  },
  computed: {
    ...mapState([ 'token', 'user', 'activeAccountSection' ])
  },
  async beforeMount() {
    if(!this.token) {
      this.$router.push('/account/signin')
    }
  }
}
