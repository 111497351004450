
export default {
  name: 'Icon',
  props:{
    name: {
      type: String,
      default: '',
    },
    classes: {
      type: String,
      default: '',
    },
  },
  computed: {
    iconName() {
      if(this.name) {
        return this.name
          .split('-')
          .map(word => word.charAt(0).toUpperCase() + word.slice(1))
          .join('');
      }
    },
    iconComponent() {
      try {
        return () => import(`./vectors/${this.iconName}.vue`)
      } catch(err) {
        console.error(err)
      }
    }
  }
}
