
import { mapState } from 'vuex'
import { Icon } from '@/components'

export default {
  name: 'HowToRedeem',
  components: {
    Icon
  },
  computed: {
    ...mapState([ 'user' ]),
  },
  props: {
    steps: {
      type: Array,
      default: () => {
        return  [
          {
            title: 'Pick A Location',
            body: 'Tincidunt ac vestibulum quis dolor turpis sit. Mollis at viverra odio.'
          },
          {
            title: 'Start Your Order',
            body: 'Tincidunt ac vestibulum quis dolor turpis sit. Mollis at viverra odio.'
          },
          {
            title: 'Add to Your Wallet',
            body: 'Tincidunt ac vestibulum quis dolor turpis sit. Mollis at viverra odio.'
          },
          {
            title: 'Redeem At Checkout',
            body: 'Tincidunt ac vestibulum quis dolor turpis sit. Mollis at viverra odio.'
          },
        ]
      }
    }
  },
}
